<template>
  <mobile-screen :header="true" screen-class="resources-screen gray-bg">
    <template v-slot:header>
      <top-header-menu-wrapper menu-class="resource-header icon-hea1">
        <template v-slot:left>
          <router-link
            :to="{
              name: backLinkName
            }"
          >
            <icon icon="#cx-hea1-arrow-left" />
          </router-link>
        </template>
        <div class="component-title">
          {{
            displayLabelName(
              "mega-containers",
              "edit-mega-container",
              "edit-mega-container"
            )
          }}
        </div>
        <template v-slot:right>
          <button @click="initSubmit">
            <icon icon="#cx-hea1-save" />
          </button>
        </template>
      </top-header-menu-wrapper>
    </template>
    <VeeForm
      ref="editMegaContainerForm"
      :key="formKey"
      @submit="submitForm"
      v-slot="{ errors }"
      class="form inline-input edit-form"
      :initial-values="selectedMegaContainer && { ...selectedMegaContainer }"
      novalidate
    >
      <ul class="clebex-item-section">
        <li
          class="clebex-item-section-item"
          v-if="selectedMegaContainer && selectedMegaContainer.id"
        >
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "mega-containers",
                  "edit-mega-container",
                  "mega-container-id"
                )
              }}</label>
              <p class="text-label">{{ selectedMegaContainer.id }}</p>
            </div>
          </div>
        </li>
        <li
          class="clebex-item-section-item"
          :class="{ 'has-error': errors.name }"
        >
          <span class="error-message" v-if="errors.name">
            {{ errors.name }}
          </span>
          <div class="clebex-item-content-wrapper">
            <div class="clebex-section-input">
              <label class="clebex-section-input-label">{{
                displayLabelName(
                  "mega-containers",
                  "edit-mega-container",
                  "name"
                )
              }}</label>
              <Field
                name="name"
                as="input"
                type="text"
                rules="required"
                :placeholder="
                  displayLabelName(
                    'mega-containers',
                    'edit-mega-container',
                    'name'
                  )
                "
              />
            </div>
          </div>
        </li>
        <li class="clebex-item-section-item">
          <router-link
            :to="{ name: 'r_edit-mega-container-select-container-type' }"
            class="clebex-item-content-wrapper"
          >
            <div
              class="clebex-section-input"
              v-if="selectedContainers && selectedContainers.length"
            >
              <div class="clebex-section-input-label">
                {{
                  displayLabelName(
                    "mega-containers",
                    "edit-mega-container",
                    "add-containers-to-mega-container"
                  )
                }}
              </div>
              <p class="text-label">
                {{ selectedContainers.map(item => item.name).join(", ") }}
              </p>
            </div>
            <span class="label" v-else>
              {{
                displayLabelName(
                  "mega-containers",
                  "edit-mega-container",
                  "add-containers-to-mega-container"
                )
              }}
            </span>
            <span class="follow-up-icons">
              <span class="follow-up-icon-item">
                <icon
                  icon="#cx-app1-arrow-right-12x12"
                  width="12"
                  height="12"
                />
              </span>
            </span>
          </router-link>
        </li>
      </ul>
      <button type="submit" style="display: none;" ref="submitFormBtn">
        Submit form
      </button>
    </VeeForm>
  </mobile-screen>
  <router-view />
</template>

<script>
import MobileScreen from "@/layouts/MobileScreen";
import TopHeaderMenuWrapper from "@/components/global/TopHeaderMenuWrapper";
import { mapActions, mapState } from "vuex";
import httpServiceAuth from "@/services/http-service";
import { apiEndpoints } from "@/services/constants";
import { errorHandler } from "@/services/error-handler";
export default {
  name: "EditMegaContainer",
  data() {
    return {
      formKey: 0
    };
  },
  computed: {
    ...mapState("megaContainers", [
      "selectedMegaContainer",
      "selectedContainers"
    ])
  },
  mounted() {
    if (!this.selectedMegaContainer) {
      this.$router.push({ name: "r_containers-mega" });
    } else {
      this.$store.commit("megaContainers/setSelectedContainers", null, {
        root: true
      });
      this.getMegaContainersContainers(this.selectedMegaContainer.id);
    }
  },
  updated() {
    if (this.formKey === 10) {
      this.formKey = 0;
    } else {
      this.formKey++;
    }
  },
  watch: {
    selectedMegaContainer() {
      if (!this.selectedMegaContainer) {
        this.$router.push({ name: "r_containers-mega" });
      } else {
        this.$store.commit("megaContainers/setSelectedContainers", null, {
          root: true
        });
        this.getMegaContainersContainers(this.selectedMegaContainer.id);
      }
    }
  },
  methods: {
    ...mapActions("megaContainers", [
      "getMegaContainers",
      "addContainersToMegaContainer",
      "getMegaContainersContainers"
    ]),
    ...mapActions("pagination", ["setPaginationData"]),
    submitForm(values) {
      if (values) {
        this.submitCompanyData(values);
      }
    },
    submitCompanyData(values) {
      this.$store.commit("loader/setScreenLoading", true, { root: true });
      httpServiceAuth
        .put(
          `${apiEndpoints.company.megaContainers}/${this.selectedMegaContainer.id}`,
          values
        )
        .then(() => {
          if (this.selectedContainers && this.selectedContainers.length) {
            this.addContainersToMegaContainer({
              id: this.selectedMegaContainer.id,
              container_ids: this.selectedContainers.map(item => item.id)
            }).finally(() => {
              this.getMegaContainersContainers(this.selectedMegaContainer.id);
            });
          }

          this.setPaginationData(null);
          this.getMegaContainers();
        })
        .catch(error => {
          if (error.response) {
            errorHandler(error.response, this.findElement());
          }
        })
        .finally(() => {
          this.$store.commit("loader/setScreenLoading", false, { root: true });
        });
    },
    initSubmit() {
      const { submitFormBtn } = this.$refs;
      submitFormBtn.click();
    }
  },
  components: {
    MobileScreen,
    TopHeaderMenuWrapper
  },
  props: {
    backLinkName: {
      type: String,
      required: true
    }
  }
};
</script>
